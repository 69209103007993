import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                ©2021 StarLink Tracker. All Rights Reserved. Website Made by Tyler Zhixing Wang
            </div>
        );
    }
}

export default Footer;
