import Header from "./Header";
import Footer from "./Footer";
import Main from "./Main";
import React from "react";
function App() {
  return (
    <div className="App">
        <Header />
        <Main/>
        <Footer />
    </div>
  );
}

export default App;
